import axios from "axios";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { DeviceUUID } from "device-uuid";
import { Token } from "utils/helpers/encrypt-decrypt-user";

const firebaseApp = initializeApp({
  apiKey: "AIzaSyAsvYXVnur7pvKmyYn-5JlqBmZxvRbnd-U",
  authDomain: "ngt-ticketing.firebaseapp.com",
  projectId: "ngt-ticketing",
  storageBucket: "ngt-ticketing.appspot.com",
  messagingSenderId: "1018816089903",
  appId: "1:1018816089903:web:a281f137922b4bb94ddbda",
});
if (firebaseApp) {
}

/**
 * disable for localhost
 */
export const messaging = getMessaging(firebaseApp);
// export const messaging = "";

export const getTokenAsync = () => {
  return getToken(messaging, {
    vapidKey:
      "BIqEufeJLEmZzDs-Dh529fiRK4DA1nn3xrQ0SouJtIObBIvtWEXTS6sDt_a3kzF9LzpibjSQsPyQ43Gvexhn4Lw",
  })
    .then((currentToken) => {
      if (currentToken) {
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
        var uuid = new DeviceUUID().get();
        if (Token.Get() !== null)
          axios.post(
            process.env.REACT_APP_API_URL + "api/add-firebase-token",
            {
              firebase_token: currentToken,
              device_id: uuid,
              platform: "Web",
              version: `${process.env.REACT_APP_VERSION}`,
            },
            {
              headers: {
                Authorization: `Bearer ${Token.Get()}`,
              },
            }
          );
        return currentToken;
      }
    })
    .catch((err) => {
      console.log("Error firebase", err);
      // catch error while creating clieernt token
    });
};
