const Footer = () => {
  return (
    <div className="footer">
      <div className="version">Version {process.env.REACT_APP_VERSION}</div>
      <div className="copyright">
        <a href={"https://ngt.aigate.me"} rel="noreferrer" target={"_blank"}>
          <span style={{ color: "#16678d" }}>NGT</span>{" "}
          <span
            style={{
              fontWeight: "bold",
              color: "orange",
            }}
          ></span>
        </a>{" "}
        2023-2024©
      </div>
    </div>
  );
};

export default Footer;
